<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Thiosulfate ion is oxidized by iodine according to the following reaction:</p>
      <p class="mb-2">
        <chemical-latex content="2S2O3^{2-}(aq) + I2(aq) → S4O6^{2-}(aq) + 2I-(aq)" />
      </p>
      <p class="mb-3">
        If, in a certain experiment,
        <number-value :value="moles" unit="\text{mol}" /> of
        <chemical-latex content="S2O3^{2-}(aq)" /> is consumed in 1.00 L of solution each second,
        what is the rate of consumption of <chemical-latex content="I2" />?
      </p>

      <calculation-input
        v-model="inputs.rate"
        prepend-text="$\text{Rate:}$"
        append-text="$\text{mol/s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemSIU131_Task16',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rate: null,
      },
    };
  },
  computed: {
    moles() {
      return this.taskState.getValueBySymbol('moles').content;
    },
  },
};
</script>
